<template>
  <v-container>
    <div class="document">
      <div class="document-form">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                  :label="$t('exchange.name')"
                  outlined
                  v-model="item.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint
                  dense>

              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                  :label="$t('exchange.short Name')"
                  outlined
                  v-model="item.short"
                  :hint="`${server_errors.short}`"
                  persistent-hint
                  dense>

              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <vuetify-money
                  dense
                  :outlined="true"
                  :options="$store.state.formatSalaryNumber"
                  :clearable="true"
                  :label="$t('exchange.rate')"
                  type="number"
                  class="input-number"
                  v-model="item.rate"
                  :hint="`${server_errors.rate}`"
                  persistent-hint
                  required
              />
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{ $t("exchange.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      item: {
        name: "",
        short: "",
        rate: ""
      },
      server_errors: {
        name: "",
        short: "",
        rate: ""
      },
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    saveChange() {
      this.$axios.post(`company/add/exchange`, this.item).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
        this.$router.push({name: "exchange.index"})
        this.btnLoading = false;
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.document {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .document-form {
    width: 500px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
